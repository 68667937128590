import { Link } from 'gatsby';
import React from 'react';
import '../styles/navbar.css';

const leftLinks = [
  {
    name: 'Home',
    url: '/',
  },
  {
    name: 'Services',
    url: '/services',
    css: 'navbar-xs',
  },
  {
    name: 'Residential',
    url: '/services/residential-surveys',
    css: 'navbar-sm',
  },
  {
    name: 'Construction',
    url: '/services/construction-services',
    css: 'navbar-sm',
  },
  {
    name: 'Legal',
    url: '/services/legal-surveys',
    css: 'navbar-sm',
  },
  {
    name: 'Articles',
    url: '/articles',
    css: 'navbar-lg',
  },
  {
    name: 'About Us',
    url: '/about-us',
  },
];

const rightLinks = [
  {
    name: 'Quote an RPR',
    url: '/order-a-real-property-report',
    css: 'navbar-md',
  },
  {
    name: 'Quote a DP',
    url: '/order-a-development-permit-survey',
    css: 'navbar-lg',
  },
];

const NavLink = (props) => {
  const classes = `navbar-item`;
  const classesActive = `navbar-item navbar-item-active`;

  const classnames = ({ href, location }) => {
    const pathname = location.pathname.endsWith('/')
      ? location.pathname.slice(0, -1)
      : location.pathname;
    switch (href) {
      case '/':
        return pathname === '' ? { className: classesActive } : { className: classes };

      case '/services':
        return href === pathname ? { className: classesActive } : { className: classes };

      default:
        return pathname.startsWith(href) ? { className: classesActive } : { className: classes };
    }
  };

  return <Link getProps={classnames} {...props} />;
};

export const Navbar = () => {
  return (
    <nav className="navbar">
      <div>
        {leftLinks.map(({ name, url, css }) => (
          <span key={url} className={css}>
            <NavLink to={url} key={url} css={css}>
              {name}
            </NavLink>
          </span>
        ))}
      </div>
      <div>
        {rightLinks.map(({ name, url, css }) => (
          <span key={url} className={css}>
            <NavLink to={url} key={url}>
              {name}
            </NavLink>
          </span>
        ))}
      </div>
    </nav>
  );
};
