import { Link } from 'gatsby';
import React from 'react';
import Comment from './icons/Comment';
import DollarSign from './icons/DollarSign';
import Facebook from './icons/Facebook';
import Hashtag from './icons/Hashtag';
import Legal from './icons/Legal';
import LinkedIn from './icons/LinkedIn';
import Links from './icons/Links';
import Map from './icons/Map';
import PhoneSquare from './icons/PhoneSquare';
import Star from './icons/Star';
import ThumbsUp from './icons/ThumbsUp';
import '../styles/footer.css';
import RandomQuote from './RandomQuote';

export const Footer = () => (
  <footer className="footer">
    <div>
      <h3>
        <Star width="1rem" height="1rem" />
        Quote a Survey
      </h3>
      <p>
        <Link to="/order-a-real-property-report">Request a Quote for a Real Property Report</Link>
      </p>
      <p>
        <Link to="/order-a-development-permit-survey">
          Request a Quote for a Development Permit Survey
        </Link>
      </p>
    </div>

    <div>
      <h3>
        <DollarSign width="1rem" height="1rem" />
        <Link to="/services/residential-surveys/compliance-costs">Compliance Costs</Link>
      </h3>
    </div>

    <div>
      <h3>
        <ThumbsUp width="1rem" height="1rem" />
        Popular Services
      </h3>
      <p>
        <Link to="/services/residential-surveys/real-property-reports">Real Property Reports</Link>
      </p>
      <p>
        <Link to="/services/construction-services/development-permits">
          Development Permit Surveys
        </Link>
      </p>
      <p>
        <Link to="/services/legal-surveys/subdivision-surveys">Subdivision Surveys</Link>
      </p>
      <p>
        <Link to="/services/legal-surveys/right-of-way-surveys">Right-of-Way Surveys</Link>
      </p>
      <p>
        <Link to="/services/legal-surveys/condominium-services">Condominium Services</Link>
      </p>
    </div>

    <div>
      <h3>
        <Hashtag width="1rem" height="1rem" />
        Keep in Touch
      </h3>
      <p>
        <a href="https://www.linkedin.com/company/axiom-geomatics" rel="me" target="l">
          <LinkedIn width="0.8rem" height="0.8rem" />
          LinkedIn
        </a>
      </p>
      <p>
        <a href="https://www.facebook.com/axiomgeomatics" rel="me" target="f">
          <Facebook width="0.8rem" height="0.8rem" />
          Facebook
        </a>
      </p>
    </div>

    <div>
      <h3>
        <Map width="1rem" height="1rem" />
        Axiom Geomatics Office
      </h3>
      <address>
        <strong>Calgary Head Office</strong>
        <br />
        15 Ranch Glen Dr NW
        <br />
        Calgary, AB
        <br />
        T3G 1T2
      </address>
    </div>

    <div>
      <h3>
        <PhoneSquare width="1rem" height="1rem" />
        Get In Touch
      </h3>
      <table className="table">
        <tbody>
          <tr>
            <td>Email</td>
            <td className="truncate">
              <a href="mailto:info@axiomgeomatics.ca">info@axiomgeomatics.ca</a>
            </td>
          </tr>
          <tr>
            <td>Calgary, AB</td>
            <td className="truncate">
              <a href="tel:1+587-315-5007">587-315-5007</a>
            </td>
          </tr>
          <tr>
            <td className="nowrap">Alberta Toll-Free</td>
            <td className="truncate">
              <a href="tel:1+587-318-3444">1-877-305-0380</a>
            </td>
          </tr>
          <tr>
            <td>Office Hours</td>
            <td className="truncate">Mon to Fri, 9a - 5p</td>
          </tr>
        </tbody>
      </table>
    </div>

    <div>
      <h3>
        <Links width="1rem" height="1rem" />
        <Link className="black" to="/links">
          Useful Links
        </Link>
      </h3>
    </div>

    <div>
      <h3>
        <Comment width="1rem" height="1rem" />
        Testimonials from Our Clients
      </h3>
      <RandomQuote />

      <h3>
        <Legal width="1rem" height="1rem" />
        <Link to="/policies-terms-conditions">Policies, Terms & Conditions</Link>
      </h3>
    </div>
    <div className="footer-copyright">
      © Copyright Axiom Geomatics {new Date().getFullYear()}
      <br />
      All Rights Reserved
    </div>
  </footer>
);
