import { Link } from 'gatsby';
import React from 'react';
import logo from '../images/logo-v2.png';
import '../styles/header.css';

export const Header = () => {
  return (
    <header className="header">
      <div>
        <Link to="/">
          <img
            src={logo}
            width="723"
            height="126"
            alt="Axiom Geomatics Logo"
            className="header-logo"
          />
        </Link>
        <a href="mailto:info@axiomgeomatics.ca">info@axiomgeomatics.ca</a>
      </div>
      <div className="header-right">
        Alberta Toll-Free <a href="tel:1-877-305-0380">1-877-305-0380</a>
        <br />
        Calgary <a href="tel:587-315-5007">587-315-5007</a>
      </div>
    </header>
  );
};
