import React from 'react';

const quotes = [
  {
    text: 'Kevin/Axiom Geomatics helped me to get DP survey along with street-scape and am very happy with his services.',
    author: 'Irshad S.',
  },
  {
    text: 'I really like your working ethics and my future first preference will be you for any survey related tasks.',
    author: 'Irshad S.',
  },
  {
    text: 'I have worked with Axiom Geomatics on numerous projects, they have constantly provided exceptional service, same-week turnaround times, and the best prices. I would recommend Axiom for any RPR and subdivision work.',
    author: 'Chris D',
  },
  {
    text: 'Axiom Geomatics helped me with all the paperwork and compliance issues I had no clue even existed. Trust this company with your business.',
    author: 'Chris D',
  },
  {
    text: 'Axiom Geomatics was the only company in Calgary that could get my RPR the same week without any extra fees. Kevin was awesome - definitely recommend you to my friends.',
    author: 'Sharlene M',
  },
  {
    text: 'They were quick to respond to my inquiry and followed up in a timely manner. Very knowledgable and explained the process as well as the timelines. They were helpful and professional. I would recommend them to others who need to get a Real Property Report done for their home.',
    author: 'F Alberto',
  },
  {
    text: "Thanks, Kevin so much for all that you've done. Although I will eventually be leaving Calgary, you can be sure that while I'm still here I will be recommending your company if anyone I know needs an RPR done. Your response was fast, your pricing very fair and your service prompt. I am a happy customer.",
    author: 'Lynne C',
  },
  {
    text: "Reliable, responsible & reputable company to obtain a RPR. Well priced, amazing service & don't make you pay for a gazillion extras to get the job done. Don't hesitate to work with these folks; was compelled not to go to original surveyor & have not regretted that move in any way. Axiom came in timely for a house sale & reduced all the stress that can be involved. Axiom is Class A!",
    author: 'Laurie J',
  },
  {
    text: 'Everyone I dealt with at Axiom was the model of professionalism. Both Kevin and Vince did all that they could to ensure my report was dealt with as quickly as possible, and kept me up to date throughout the entire process. Great to work with an entire group so dedicated to providing the best customer experience possible.',
    author: 'Andrew S',
  },
  {
    text: 'Axiom Geomatics Surveyors & Kevin Nemrava have provided the real property reports for two our house. The service has been exceptional. We have met all timelines and received the stamp of compliance with the City of Calgary. Kevin and his company are strongly recommended for everyone who needs accurate and quick service. Kudos!',
    author: 'Olga I',
  },
  {
    text: 'I hired Axiom Geomatics do the real property report (RPR) on a house that I was selling. From the get go, they were very friendly, honest, prompt and responsive with all my questions. Service and turnaround was quick. I think they completed the RPR in a few days from calling them. Great customer service! Very professional and I would recommend them to anyone looking to get a survey done.',
    author: 'Tim C',
  },
  {
    text: 'The service level the team at Axiom deliver is nothing short of outstanding.  Kevin is a great person to work with and I would recommend him without hesitation',
    author: 'Wayne W',
  },
];

const getQuote = () => {
  const num = Math.floor(Math.random() * quotes.length);
  return quotes[num];
};

const RandomQuote = () => {
  const { text, author } = getQuote();

  return (
    <blockquote>
      {text}
      <cite>{author}</cite>
    </blockquote>
  );
};

export default RandomQuote;
