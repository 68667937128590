import { useLocation } from '@reach/router';
import { Link } from 'gatsby';
import React from 'react';
import '../styles/breadcrumbs.css';

export const Breadcrumbs = () => {
  const { pathname } = useLocation();

  const crumbs = pathname
    .replace(/-/g, ' ')
    .split('/')
    .slice(1)
    .filter((val) => val);

  const getUrl = (ndx) =>
    pathname
      .split('/')
      .slice(0, ndx + 2)
      .join('/');

  return (
    <ul className="breadcrumb" itemScope="" itemType="http://schema.org/BreadcrumbList">
      <li
        className="breadcrumb-item"
        itemProp="itemListElement"
        itemScope=""
        itemType="http://schema.org/ListItem"
        key="home"
      >
        <Link
          to="/"
          className="active"
          itemScope=""
          itemType="http://schema.org/Thing"
          itemProp="item"
          itemID="/"
        >
          <span itemProp="name">Home</span>
        </Link>
        <meta itemProp="position" content={1} />
      </li>

      {crumbs.map((crumb, ndx) => (
        <li
          className="breadcrumb-item"
          itemProp="itemListElement"
          itemScope=""
          itemType="http://schema.org/ListItem"
          key={crumb}
        >
          <Link
            to={getUrl(ndx)}
            className="active"
            itemScope=""
            itemType="http://schema.org/Thing"
            itemProp="item"
            itemID={getUrl(ndx)}
          >
            <span itemProp="name">{crumb}</span>
          </Link>
          <meta itemProp="position" content={ndx + 1} />
        </li>
      ))}
    </ul>
  );
};
