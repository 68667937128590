import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import '../styles/layout.css';
import '../styles/utilities.css';
import { Breadcrumbs } from './Breadcrumbs';
import { Footer } from './Footer';
import { Header } from './Header';
import { Navbar } from './Navbar';

export const Layout = ({ children, title, description, keywords }) => {
  useEffect(() => {
    // protect against SSR
    if (typeof window === undefined || !window.gtag) {
      return;
    }

    window.gtag('event', 'page_view', {
      page_title: title,
      page_location: window.location.href,
      page_path: window.location.pathname,
    });
  }, [title]);

  return (
    <>
      <Helmet htmlAttributes={{ lang: 'en' }}>
        <meta charSet="utf-8" />
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
        <meta name="theme-color" content="#0077c8" />

        <title>{title || 'Axiom Geomatics Land Surveyors'}</title>

        {description && <meta name="description" content={description} />}
        {keywords && <meta name="keywords" content={keywords} />}
        <link
          href="https://fonts.googleapis.com/css?family=Lato:300,700&amp;display=swap"
          rel="stylesheet"
        />
      </Helmet>

      {/*<Covid />*/}
      <Header />
      <Navbar />
      <Breadcrumbs />
      <div className="content">{children}</div>
      <Footer />

      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify([
            {
              '@context': 'http://schema.org',
              '@type': 'Organization',
              name: 'Axiom Geomatics',
              url: 'https://axiomgeomatics.ca/',
              logo: 'https://axiomgeomatics.ca/axiom-geomatics-logo.gif',
              sameAs: [
                'https://www.linkedin.com/company/axiom-geomatics',
                'https://www.facebook.com/axiomgeomatics',
                'https://twitter.com/axiomgeomatics',
              ],
              contactPoint: [
                {
                  '@type': 'ContactPoint',
                  telephone: '+1-877-305-0380',
                  contactOption: 'TollFree',
                  contactType: 'customer service',
                  availableLanguage: 'English',
                  areaServed: 'CA',
                  hoursAvailable: 'Mo,Tu,We,Th,Fr 08:00-17:00',
                },
                {
                  '@type': 'ContactPoint',
                  telephone: '+1-587-315-5007',
                  contactType: 'customer service',
                  availableLanguage: 'English',
                  areaServed: 'CA',
                  hoursAvailable: 'Mo,Tu,We,Th,Fr 08:00-17:00',
                },
              ],
            },
            {
              '@context': 'http://schema.org',
              '@type': 'WebSite',
              url: 'https://axiomgeomatics.ca/',
              name: 'Axiom Geomatics Professional Surveyors, Calgary & Red Deer',
            },
            {
              '@context': 'http://schema.org',
              '@type': 'LocalBusiness',
              '@id': 'https://axiomgeomatics.ca/',
              name: 'Axiom Geomatics',
              image: 'https://axiomgeomatics.ca/axiom.png',
              email: 'info@axiomgeomatics.ca',
              telephone: '1-877-305-0380',
              address: [
                {
                  '@type': 'PostalAddress',
                  name: 'Calgary Head Office',
                  streetAddress: '15 Ranch Glen Dr NW',
                  addressLocality: 'Calgary',
                  addressRegion: 'AB',
                  addressCountry: 'CA',
                  postalCode: 'T3G 1T2',
                },
              ],
              serviceArea: [
                {
                  '@type': 'PostalAddress',
                  addressLocality: 'Calgary',
                  addressRegion: 'AB',
                  addressCountry: 'CA',
                },
              ],
              priceRange: '$$ - $$$',
              hasOfferCatalog: {
                '@type': 'OfferCatalog',
                itemListElement: [
                  {
                    '@type': 'Offer',
                    itemOffered: {
                      '@type': 'Service',
                      name: 'Real Property Reports',
                      url: 'https://axiomgeomatics.ca/services/residential-surveys/real-property-reports',
                    },
                  },
                  {
                    '@type': 'Offer',
                    itemOffered: {
                      '@type': 'Service',
                      name: 'Property & Fence Line Surveys',
                      url: 'https://axiomgeomatics.ca/services/residential-surveys/property-line-surveys',
                    },
                  },
                  {
                    '@type': 'Offer',
                    itemOffered: {
                      '@type': 'Service',
                      name: 'Development Permit Surveys',
                      url: 'https://axiomgeomatics.ca/services/construction-services/development-permits',
                    },
                  },
                  {
                    '@type': 'Offer',
                    itemOffered: {
                      '@type': 'Service',
                      name: 'Streetscapes',
                      url: 'https://axiomgeomatics.ca/services/construction-services/streetscapes',
                    },
                  },
                  {
                    '@type': 'Offer',
                    itemOffered: {
                      '@type': 'Service',
                      name: 'Plot Plans',
                      url: 'https://axiomgeomatics.ca/services/construction-services/plot-plans',
                    },
                  },
                  {
                    '@type': 'Offer',
                    itemOffered: {
                      '@type': 'Service',
                      name: 'House Stakeouts',
                      url: 'https://axiomgeomatics.ca/services/construction-services/house-stakeouts',
                    },
                  },
                  {
                    '@type': 'Offer',
                    itemOffered: {
                      '@type': 'Service',
                      name: 'Main Floor & Footing Elevation Surveys',
                      url: 'https://axiomgeomatics.ca/services/construction-services/main-floor-and-footing-elevation-surveys',
                    },
                  },
                  {
                    '@type': 'Offer',
                    itemOffered: {
                      '@type': 'Service',
                      name: 'Lot Grading Surveys & Certificates',
                      url: 'https://axiomgeomatics.ca/services/construction-services/lot-grading-certificates',
                    },
                  },
                  {
                    '@type': 'Offer',
                    itemOffered: {
                      '@type': 'Service',
                      name: 'Utility Construction Surveys',
                      url: 'https://axiomgeomatics.ca/services/construction-services/utility-construction-surveys',
                    },
                  },
                  {
                    '@type': 'Offer',
                    itemOffered: {
                      '@type': 'Service',
                      name: 'Large Building Construction',
                      url: 'https://axiomgeomatics.ca/services/construction-services/large-building-construction',
                    },
                  },
                  {
                    '@type': 'Offer',
                    itemOffered: {
                      '@type': 'Service',
                      name: 'Engineering Surveys',
                      url: 'https://axiomgeomatics.ca/services/construction-services/engineering-surveys',
                    },
                  },
                  {
                    '@type': 'Offer',
                    itemOffered: {
                      '@type': 'Service',
                      name: 'Subdivision Surveys',
                      url: 'https://axiomgeomatics.ca/services/legal-surveys/subdivision-surveys',
                    },
                  },
                  {
                    '@type': 'Offer',
                    itemOffered: {
                      '@type': 'Service',
                      name: 'Right-of-Way Surveys',
                      url: 'https://axiomgeomatics.ca/services/legal-surveys/right-of-way-surveys',
                    },
                  },
                  {
                    '@type': 'Offer',
                    itemOffered: {
                      '@type': 'Service',
                      name: 'Condominium Services',
                      url: 'https://axiomgeomatics.ca/services/legal-surveys/condominium-services',
                    },
                  },
                  {
                    '@type': 'Offer',
                    itemOffered: {
                      '@type': 'Service',
                      name: 'Lease Area Surveys',
                      url: 'https://axiomgeomatics.ca/services/legal-surveys/lease-area-surveys',
                    },
                  },
                ],
              },
            },
          ]),
        }}
      />
      {process.env.NODE_ENV === 'production' && (
        <script defer async src="https://embed.tawk.to/598f41d4dbb01a218b4dc13d/default" />
      )}
    </>
  );
};
